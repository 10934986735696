import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import parse from 'html-react-parser'
import Heading from '../../../components/ui/heading'
import Text from '../../../components/ui/text'
// import ProgressBar from '../../../components/ui/progress-bar/layout-one'
import AgileDiagram from '../../../data/images/features/agile.svg'
import AgileDiagramMobile from '../../../data/images/features/mobile_agile.svg'

import { Container, Row, Col } from '../../../components/ui/wrapper'
import { SectionWrap, SectionTitle } from './style'

const OurSkills = props => {
    const getSkillData = useStaticQuery(graphql`
        query GET_SKILL_DATA {
            pageIntro: agileCultureJson(id: {eq: "agile-culrure-intro"}) {
                title
                desc
            }
        }
    `)
    const { pageIntro: { title, desc}} = getSkillData
    const { titleStyle, descStyle } = props;
    return (
        <SectionWrap>
            <Container>
                <Row>
                    <Col lg={12}>
                        <SectionTitle>
                            {title && <Heading {...titleStyle}>{parse(title)}</Heading>}
                            {desc && <Text {...descStyle}>{desc}</Text>}
                        </SectionTitle>
                    </Col>
                </Row>
                <Row>
                    <img src={AgileDiagram} className="agile-diagram desktop" alt="agile"/>
                    <img src={AgileDiagramMobile} className="agile-diagram mobile" alt="agile"/>
                </Row>
            </Container>
        </SectionWrap>
    )
}
OurSkills.propTypes = {
    titleStyle: PropTypes.object,
    descStyle: PropTypes.object,
}

OurSkills.defaultProps = {
    titleStyle: {
        as: 'h3',
        mb: '20px',
        child: {
            color: 'primary'
        }
    },
    descStyle: {
        maxwidth: '760px',
        m: 'auto'
    }
}


export default OurSkills
