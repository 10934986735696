import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-one'
import Footer from '../containers/layout/footer/footer-one'
import PageHeader from '../components/pageheader/agileheader'
import SkillArea from '../containers/agile-culture/our-skills'
import ServicesArea from '../containers/agile-culture/services-area'
import ContactArea from '../containers/index-appointment/contact-area'
// import AgileDiagram from '../data/images/features/agile.svg'

const WhyChooseUsPage = ({ pageContext, location }) => (
  <Layout location={location}>
    <SEO 
      title="Agile Culture"
      description="We deliver business and technology transformation from start till the end by leveraging agile methodologies, client collaboration frameworks, dedicated hybrid teams and proven product engineering processes."
    />
    <Header />
    <PageHeader
      pageContext={pageContext}
      location={location}
      title="Agile Culture"
    />
    <main className="site-wrapper-reveal">
      <SkillArea />
      <ServicesArea />
      <ContactArea />
    </main>
    <Footer />
  </Layout>
)

export default WhyChooseUsPage
