import styled from 'styled-components'
import { device } from '../../../theme'

export const SectionWrap = styled.section`
    @media (prefers-color-scheme: dark) {
        background: #121212;
    }
    // @media (prefers-color-scheme: light) {
    //     background: #f6f2ed;
    // }
    background: #f6f2ed;
    padding-top: 89px;
    padding-bottom: 45px;
    @media ${device.medium}{
        padding-top: 72px;
        padding-bottom: 25px;

        .agile-diagram.desktop {
            display: block;
        }
    }
    @media ${device.small}{
        padding-top: 53px;
        padding-bottom: 19px;
        .agile-diagram.desktop {
            display: none;
        }
        .agile-diagram.mobile{
            display: block !important;
            width: 80%;
            padding: 0 30px 30px 30px;
            margin: 0 auto;
            max-width: 240px;
            max-height: 1100px;

        }
    }
    .agile-diagram{
        width: 100%;
        padding: 30px;
    }

    .agile-diagram.mobile{
        display: none;
    }
`;

export const SectionTitle = styled.div`
    text-align: center;
    margin-bottom: 30px;
`;

export const LeftBox = styled.div`
    margin-top: 15px;
    @media ${device.medium}{
        margin-bottom: 35px;
    }
`;

export const RightBox = styled.div``;